#pretty-math-assistant-portal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none; }

.assistant {
  background-color: #F6F8F9;
  border: 1px solid #E4E6E8;
  border-radius: 6px;
  box-shadow: 0 2px 10px 1px #cccccc;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  min-width: 300px;
  padding-top: 0.5em;
  pointer-events: auto;
  position: relative;
  text-align: left; }
  .assistant ul {
    padding: 0;
    margin: 0;
    list-style: none; }
  .assistant .focused-token {
    display: flex;
    padding: 0.5em 1em 0; }
    .assistant .focused-token .token {
      padding: 0.5em; }
    .assistant .focused-token .token-info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #98A0B5;
      font-weight: 300;
      margin-left: 0.5em; }
      .assistant .focused-token .token-info .row {
        margin-bottom: 0.25em; }
      .assistant .focused-token .token-info .description {
        font-size: 0.9em; }
      .assistant .focused-token .token-info .evaluation {
        font-size: 0.9em; }
    .assistant .focused-token.active {
      background-color: rgba(85, 179, 105, 0.3); }
  .assistant .library {
    position: relative; }
    .assistant .library::-webkit-scrollbar {
      width: 0 !important; }
    .assistant .library .tabs {
      padding: 0 1em;
      display: flex;
      flex-direction: row;
      text-transform: uppercase;
      color: #FF3465; }
      .assistant .library .tabs .tab {
        padding: 0.35em 0.45em;
        border-bottom: 3px solid transparent;
        font-size: 0.8em;
        font-weight: 500;
        letter-spacing: 1px;
        cursor: pointer;
        margin: 0 2px; }
        .assistant .library .tabs .tab.active-tab {
          border-bottom: 3px solid #FF3465; }
        .assistant .library .tabs .tab:hover {
          border-bottom: 3px solid #FF3465; }
    .assistant .library .tab-content {
      background-color: #F6F8F9;
      border-top: 1px solid #E4E6E8;
      border-bottom: 1px solid #E4E6E8; }
    .assistant .library .entry-list-scroller {
      position: relative;
      max-height: 11em;
      overflow-y: auto; }
      .assistant .library .entry-list-scroller::-webkit-scrollbar {
        width: 0 !important; }
    .assistant .library.full-library .entry-list-scroller {
      max-height: 22em; }
    .assistant .library .library-entry-list {
      width: 400px;
      padding-bottom: 2.25em; }
      .assistant .library .library-entry-list .library-entry-item {
        padding: 0.5em 1em;
        cursor: pointer;
        display: flex;
        align-items: baseline; }
        .assistant .library .library-entry-list .library-entry-item .descr {
          margin-left: auto;
          font-size: 0.8em; }
        .assistant .library .library-entry-list .library-entry-item:hover {
          background-color: #f0f3f5; }
        .assistant .library .library-entry-list .library-entry-item.focused {
          background-color: rgba(85, 179, 105, 0.3); }
    .assistant .library .empty-list {
      color: grey;
      font-weight: 200;
      padding: 1em;
      padding-bottom: 2.25em;
      text-align: center; }
  .assistant .hint-wrapper {
    background: white;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 50%, white 100%);
    bottom: 0;
    padding: 1.5em 1em 0.25em 1em;
    position: absolute;
    width: 100%;
    font-size: 0.8em;
    color: #666666; }
    .assistant .hint-wrapper .full-library-button {
      color: #FF3465;
      text-decoration: underline;
      cursor: pointer; }

.assistant__positioner {
  position: absolute; }

.math-root {
  display: inline-block;
  position: relative;
  font-family: "Times New Roman", Symbola, serif;
  line-height: normal;
  white-space: nowrap; }
  .math-root .block-chain {
    display: inline-flex;
    align-items: baseline; }
  .math-root .block {
    display: inline-block;
    white-space: pre; }
  .math-root .block-has-error {
    display: inline-block;
    position: relative;
    background: url(/assets/red_squiggly.4e82f047.gif) bottom repeat-x;
    background-color: rgba(255, 118, 118, 0.3); }
  .math-root .block.block-has-error:hover .block-error-message {
    display: block; }
  .math-root .block-error-message {
    position: absolute;
    background-color: red;
    color: white;
    display: none; }
  .math-root .differential {
    font-style: italic; }
  .math-root .italic {
    font-style: italic; }
  .math-root .symbol {
    font-style: italic; }
  .math-root .function-name .symbol {
    font-style: normal;
    font-style: initial; }
  .math-root .blank {
    background-color: lightgrey;
    margin-right: 1px; }
    .math-root .blank:after {
      content: 'a';
      opacity: 0; }
    .focused .math-root .blank {
      background-color: transparent; }
      .focused .math-root .blank.no-cursor {
        background-color: lightgrey; }
  .math-root .static-blank:after {
    content: 'i';
    opacity: 0; }
  .math-root .parens {
    align-self: stretch; }
  .math-root .binary-op {
    padding-left: 0.2em;
    padding-right: 0.2em; }
    .math-root .binary-op.no-padding-right {
      padding-right: 0; }
    .math-root .binary-op.no-padding-left {
      padding-left: 0; }
  .math-root .radical {
    position: relative;
    display: inline-flex; }
    .math-root .radical .inner {
      padding: 0.1em 1px 0 1px;
      margin: 0.1em 0.1em 0 1em; }
    .math-root .radical .radical-symbol {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: visible;
      pointer-events: none; }
  .math-root .integral {
    position: relative;
    display: inline-flex;
    align-items: baseline;
    padding-left: 0.4rem;
    padding-right: 0.4rem; }
    .math-root .integral .integral-symbol {
      font-size: 3rem;
      font-family: 'Segio UI';
      margin-right: 0.7rem;
      vertical-align: middle; }
      .math-root .integral .integral-symbol span {
        vertical-align: inherit; }
    .math-root .integral .integral-bounds {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-self: normal; }
      .math-root .integral .integral-bounds .right-bound {
        font-size: 80%; }
      .math-root .integral .integral-bounds .left-bound {
        font-size: 80%;
        margin-left: -0.4rem;
        margin-bottom: -0.3rem; }
    .math-root .integral .inner {
      margin-left: 0.1rem;
      margin-right: 0.2rem; }
    .math-root .integral .wrt {
      margin-left: 0.2rem;
      font-style: italic; }
  .math-root .derivative {
    display: inline-block; }
  .math-root .inner {
    display: inline-block;
    padding: 0 1px 0 1px; }
  .math-root .function {
    display: inline-block; }
    .math-root .function .function-name {
      margin-right: 0.05em; }
  .math-root .fraction {
    display: inline-block;
    vertical-align: middle;
    padding-left: 0.2em;
    padding-right: 0.2em; }
    .math-root .fraction .numerator {
      float: left;
      width: 100%;
      text-align: center;
      margin-bottom: -0.62em;
      padding-left: 1px; }
    .math-root .fraction .fraction-line {
      display: block;
      clear: both;
      height: 1em;
      pointer-events: none; }
      .math-root .fraction .fraction-line .line {
        display: inline-block;
        width: 100%;
        vertical-align: 0.25em;
        border-bottom: solid 1px; }
    .math-root .fraction .denominator {
      float: left;
      width: 100%;
      text-align: center;
      margin-top: -0.31em;
      padding-left: 1px; }
  .math-root .sup-sub {
    display: inline-block;
    position: relative;
    text-align: left; }
    .math-root .sup-sub .sup {
      float: left;
      clear: both;
      margin-bottom: -.9257em;
      margin-top: 0;
      padding-left: 1px;
      font-size: 0.7em; }
    .math-root .sup-sub .sup:last-child {
      margin-bottom: -1.09em; }
    .math-root .sup-sub .middle-base {
      display: block;
      clear: both;
      height: 1em; }
      .math-root .sup-sub .middle-base .inline {
        display: inline-block; }
    .math-root .sup-sub .sub {
      float: left;
      clear: both;
      margin-top: -0.547899em;
      margin-bottom: 0;
      padding-left: 1px;
      font-size: 0.7em; }
      .math-root .sup-sub .sub .symbol {
        font-style: normal; }
  .math-root .matrix {
    align-items: center;
    display: inline-flex;
    position: relative; }
    .math-root .matrix .base-line {
      display: inline-block;
      height: inherit;
      margin-top: -0.18em;
      overflow: hidden;
      width: 0; }
    .math-root .matrix .matrix-bracket {
      position: relative;
      pointer-events: none; }
    .math-root .matrix td {
      border: 1px solid transparent;
      padding: .2rem .4rem;
      text-align: center; }
      .math-root .matrix td:first-child {
        padding-left: .2rem; }
      .math-root .matrix td:last-child {
        padding-right: .2rem; }
    .math-root .matrix.focus-inside td {
      border: 1px dashed lightblue; }
    .math-root .matrix .matrix-config-button {
      position: absolute;
      right: 0;
      top: -3px;
      -webkit-transform: translateX(100%);
              transform: translateX(100%); }

.math-field {
  display: inline-block;
  position: relative;
  width: 100%;
  cursor: text;
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-sizing: border-box; }
  .math-field:hover {
    box-shadow: 0 0 0 1px lightgrey; }
  .math-field.error {
    border: 1px solid #b41d19;
    box-shadow: 0 0 5px #b41d19; }
  .math-field .overlay-portal {
    position: absolute;
    top: 0;
    left: 0; }
  .math-field .hidden-textarea {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: scale(0);
            transform: scale(0); }
  .math-field .cursor {
    display: none;
    border-left: 1px solid blue;
    -webkit-animation: 1s cursor-blink step-end infinite;
            animation: 1s cursor-blink step-end infinite;
    margin-left: -1px; }
  .math-field .focused-word-indicator {
    position: absolute;
    pointer-events: none;
    background-color: antiquewhite;
    opacity: 0.4; }
  .math-field .assistant-container {
    display: none;
    position: absolute;
    top: calc(100% + 3px);
    box-sizing: border-box;
    font-size: 16px;
    width: 400px; }
  .math-field.focused {
    outline: none;
    border: 1px solid #4D90FE;
    box-shadow: 0 0 5px #4D90FE; }
    .math-field.focused .assistant-container {
      display: block; }
    .math-field.focused .focused-token {
      background-color: rgba(250, 235, 215, 0.4); }
    .math-field.focused .selected {
      background-color: lightblue; }
    .math-field.focused .outline {
      outline: 1px solid lightgrey; }
    .math-field.focused .cursor {
      display: inline; }
  .math-field .block-error-dialog {
    position: absolute;
    border: 1px solid red;
    border-radius: 5px;
    background-color: rgba(255, 118, 118, 0.3);
    font-family: serif;
    font-size: medium;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    font: initial;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    min-width: 150px;
    padding: 5px; }

@-webkit-keyframes cursor-blink {
  from, to {
    border-left: 1px solid; }
  50% {
    border-left: 1px solid transparent; } }

@keyframes cursor-blink {
  from, to {
    border-left: 1px solid; }
  50% {
    border-left: 1px solid transparent; } }

.math-field .cursor {
  z-index: 1; }

#pretty-math-overlay-portal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none; }
