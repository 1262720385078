#landing-page {
  background-color: #1A1A1A;
  -webkit-overflow-scrolling: touch; }
  #landing-page a {
    color: #fff;
    cursor: pointer;
    text-decoration: none; }
    #landing-page a:visited {
      color: #fff; }
  #landing-page #landing-header {
    background-color: #262626; }
    #landing-page #landing-header .dashboard-link {
      cursor: pointer;
      display: flex;
      height: 70%; }
      #landing-page #landing-header .dashboard-link .logo {
        height: 100%; }
  #landing-page #section-1 {
    background: url("/assets/landing-background-01.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  #landing-page #section-2 {
    background: url("/assets/landing-background-02.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  #landing-page #section-3 {
    background: url("/assets/landing-background-03.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top; }
  #landing-page #section-4 {
    background: url("/assets/landing-background-04.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top; }
  #landing-page .landing-screenshot {
    transition: opacity 1s;
    opacity: 0; }
    #landing-page .landing-screenshot.landing-screenshot-visible {
      opacity: 1; }
  #landing-page #landing-demo {
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: top;
            transform-origin: top; }
    #landing-page #landing-demo::before {
      content: "";
      position: absolute;
      top: -7px;
      left: calc(50% - 5px);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff; }
    #landing-page #landing-demo.demo-visible {
      -webkit-transform: scaleY(1);
              transform: scaleY(1); }
  #landing-page .icon-description {
    transition: opacity 1s;
    z-index: auto;
    z-index: initial;
    opacity: 0; }
    #landing-page .icon-description.icon-description-visible {
      z-index: 1;
      opacity: 1; }
  #landing-page .text-pink-landing {
    color: #ff286c; }
    #landing-page .text-pink-landing:visited {
      color: #ff286c; }
  #landing-page .text-landing-gray {
    color: rgba(255, 255, 255, 0.65); }
  #landing-page .join-button {
    cursor: pointer;
    border: 1px solid #ff286c;
    background-color: #ff286c; }
    #landing-page .join-button:hover {
      border: 1px solid #f90e59;
      background-color: #f90e59; }
  #landing-page .watch-button {
    cursor: pointer;
    border: 1px solid #fff;
    background-color: transparent; }
    #landing-page .watch-button:hover {
      background-color: rgba(255, 255, 255, 0.05); }
  #landing-page .landing-video-modal {
    width: 80%;
    height: 80%; }
  #landing-page h2 {
    color: rgba(255, 255, 255, 0.65); }
  #landing-page .page-gradient {
    background: #1a1a1a;
    background: linear-gradient(0deg, #1a1a1a 0%, rgba(26, 26, 26, 0) 10%); }
